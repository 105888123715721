.modal {
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

.fade-in-image {
    animation: fadeIn 500ms ease-out;
}

.fade-out-image {
    animation: fadeOut 500ms ease-in;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.certThumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 16px;
    cursor: pointer;
}

.certThumb:hover {
    /*transform: scale(1.05);*/
    /*filter: hue-rotate(90deg);*/
    filter: contrast(120%);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
}

.videoThumb:hover {
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}