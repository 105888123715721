.activeLink {
  border-bottom: 1px solid #0E86D4;
}
.image_rotate {
    -webkit-animation: rotation 700ms infinite linear;
            animation: rotation 700ms infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(359deg);
                transform: rotateY(359deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
    to {
        -webkit-transform: rotateY(359deg);
                transform: rotateY(359deg);
    }
}
.modal {
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}

.fade-in-image {
    -webkit-animation: fadeIn 500ms ease-out;
            animation: fadeIn 500ms ease-out;
}

.fade-out-image {
    -webkit-animation: fadeOut 500ms ease-in;
            animation: fadeOut 500ms ease-in;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.certThumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    vertical-align: middle;
    border-radius: 16px;
    cursor: pointer;
}

.certThumb:hover {
    /*transform: scale(1.05);*/
    /*filter: hue-rotate(90deg);*/
    -webkit-filter: contrast(120%);
            filter: contrast(120%);
    box-shadow: rgba(0, 0, 0, 0.5) 0px 25px 50px -12px;
}

.videoThumb:hover {
    box-shadow: rgba(0, 0, 0, 1) 0px 5px 15px;
}
.fade-left {
    -webkit-animation: fade-left 500ms ease-out;
            animation: fade-left 500ms ease-out;
}

.fade-right {
    -webkit-animation: fade-right 500ms ease-out;
            animation: fade-right 500ms ease-out;
}

.fade-in-image {
    -webkit-animation: fadeIn 500ms ease-in;
            animation: fadeIn 500ms ease-in;
}

@-webkit-keyframes fadeIn  {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeIn  {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(200px);
                transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        -webkit-transform: translateX(200px);
                transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}
.reveal {
    position: relative;
    opacity: 0;
}

.reveal.active {
    opacity: 1;
}

/* Rule of thumb
    ease-in: when things are moving out.
    ease-out: when things are moving in.
*/
.active.fade-bottom {
    -webkit-animation: fade-bottom 500ms ease-out;
            animation: fade-bottom 500ms ease-out;
}

.active.fade-left {
    -webkit-animation: fade-left 500s ease-out;
            animation: fade-left 500s ease-out;
}

.active.fade-right {
    -webkit-animation: fade-right 500ms ease-out;
            animation: fade-right 500ms ease-out;
}

@-webkit-keyframes fade-bottom {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-bottom {
    0% {
        -webkit-transform: translateY(50px);
                transform: translateY(50px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-left {
    0% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        -webkit-transform: translateX(-200px);
                transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes fade-right {
    0% {
        -webkit-transform: translateX(200px);
                transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        -webkit-transform: translateX(200px);
                transform: translateX(200px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
        opacity: 1;
    }
}
.fixed {
    position: fixed;
    width: 100%;
    z-index: 10;
}

