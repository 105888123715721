.fade-left {
    animation: fade-left 500ms ease-out;
}

.fade-right {
    animation: fade-right 500ms ease-out;
}

.fade-in-image {
    animation: fadeIn 500ms ease-in;
}

@keyframes fadeIn  {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fade-left {
    0% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(200px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}