.image_rotate {
    animation: rotation 700ms infinite linear;
}

@keyframes rotation {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(359deg);
    }
}